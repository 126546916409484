.nav-mainColor {
  color: rgb(255, 255, 255) !important;
}

.offCanvas-lightMode {
  color: rgb(0, 0, 0) !important;
}

.offCanvas-DarkMode {
  color: rgb(255, 255, 255) !important;
}

.active {
  color: red !important;
}

.nav-background {
  font-size: large;
  background-color: rgba(0, 0, 0, 0.2);
}

.nav-background-onScroll {
  font-size: large;
  background-color: rgba(0, 0, 0, 0);
}

.nav {
  z-index: 1 !important;
}


/* Toggle Button From Uiverse.io by Shoh2008 */
.checkbox-wrapper-8 .tgl {
  display: none;
}

.checkbox-wrapper-8 .tgl,
.checkbox-wrapper-8 .tgl:after,
.checkbox-wrapper-8 .tgl:before,
.checkbox-wrapper-8 .tgl *,
.checkbox-wrapper-8 .tgl *:after,
.checkbox-wrapper-8 .tgl *:before,
.checkbox-wrapper-8 .tgl+.tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-8 .tgl::-moz-selection,
.checkbox-wrapper-8 .tgl:after::-moz-selection,
.checkbox-wrapper-8 .tgl:before::-moz-selection,
.checkbox-wrapper-8 .tgl *::-moz-selection,
.checkbox-wrapper-8 .tgl *:after::-moz-selection,
.checkbox-wrapper-8 .tgl *:before::-moz-selection,
.checkbox-wrapper-8 .tgl+.tgl-btn::-moz-selection,
.checkbox-wrapper-8 .tgl::selection,
.checkbox-wrapper-8 .tgl:after::selection,
.checkbox-wrapper-8 .tgl:before::selection,
.checkbox-wrapper-8 .tgl *::selection,
.checkbox-wrapper-8 .tgl *:after::selection,
.checkbox-wrapper-8 .tgl *:before::selection,
.checkbox-wrapper-8 .tgl+.tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-8 .tgl+.tgl-btn {
  outline: 0;
  display: block;
  width: 6em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-8 .tgl+.tgl-btn:after,
.checkbox-wrapper-8 .tgl+.tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-8 .tgl+.tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-8 .tgl+.tgl-btn:before {
  display: none;
}

.checkbox-wrapper-8 .tgl:checked+.tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-8 .tgl-skewed+.tgl-btn {
  overflow: hidden;
  transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  font-family: sans-serif;
  background: rgba(34, 160, 61, 0.7);
}

.checkbox-wrapper-8 .tgl-skewed+.tgl-btn:after,
.checkbox-wrapper-8 .tgl-skewed+.tgl-btn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.checkbox-wrapper-8 .tgl-skewed+.tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}

.checkbox-wrapper-8 .tgl-skewed+.tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}

.checkbox-wrapper-8 .tgl-skewed+.tgl-btn:active {
  background: #888;
}

.checkbox-wrapper-8 .tgl-skewed+.tgl-btn:active:before {
  left: -10%;
}

.checkbox-wrapper-8 .tgl-skewed:checked+.tgl-btn {
  background: rgba(0, 0, 0, 0.65);
}

.checkbox-wrapper-8 .tgl-skewed:checked+.tgl-btn:before {
  left: -100%;
}

.checkbox-wrapper-8 .tgl-skewed:checked+.tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-8 .tgl-skewed:checked+.tgl-btn:active:after {
  left: 10%;
}


/* Download Resume Button From Uiverse.io by andrew-demchenk0 */ 
.button {
  position: relative;
  width: 265px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #17795E;
  background-color: #209978;
  overflow: hidden;
}

.button, .button__icon, .button__text {
  transition: all 0.3s;
}

.button .button__text {
  transform: translateX(30px);
  color: #fff;
  font-weight: 600;
}

.button .button__icon {
  position: absolute;
  transform: translateX(210px);
  height: 100%;
  width: 45px;
  background-color: #17795E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button .svg {
  width: 20px;
  fill: #fff;
}

.button:hover {
  background: #17795E;
}

.button:hover .button__text {
  color: transparent;
}

.button:hover .button__icon {
  width: 250px;
  transform: translateX(0);
}

.button:active .button__icon {
  background-color: #146c54;
}

.button:active {
  border: 1px solid #146c54;
}
/* Download Resume button Ends here */