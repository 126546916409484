/* Card container styles */
.Projectcontainer .card {
    width: 320px;
    height: 230px;
    border-radius: 20px;
    box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    position: relative;
    /* Ensures that absolute elements are positioned relative to the card */
    display: flex;
    flex-direction: column;
}

/* Hover effect to increase card height */
.Projectcontainer .card:hover {
    height: 360px;
}

/* Image box styles */
.Projectcontainer .card .imgBx {
    overflow: hidden;
    top: 10px;
    width: 300px;
    height: 150px;
    border-radius: 15px;
    transition: 0.4s;
}

/* Image hover effect */
.Projectcontainer .card:hover .imgBx {
    top: -80px;
    scale: 0.75;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}

/* Image box hover zoom effect */
.imgBx:hover {
    scale: 1.2 !important;
    transition: 0.4s;
}

/* Image fit within the image box */
.Projectcontainer .card .imgBx img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Content styles */
.Projectcontainer .card .content {
    top: 180px;
    height: 40px;
    transition: 0.4s;
}

/* Content hover transition */
.Projectcontainer .card:hover .content {
    top: 80px;
    height: 300px;
    transition: 0.4s;
}

/* Footer styles */
.card-foot {
    position: absolute;
    bottom: -100px;
    /* Hide the footer below the card initially */
    left: 0;
    right: 0;
    /* background-color: #f8f9fa; */
    transition: all 0.4s ease;
    /* Smooth transition for footer */
    opacity: 0;
    /* Initially hidden */
}

/* Footer hover effect to slide it up from the bottom */
.Projectcontainer .card:hover .card-foot {
    bottom: 35px;
    /* Moves footer to the bottom */
    opacity: 1;
    /* Makes the footer visible */
}