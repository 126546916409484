.madimi-one-regular {
    font-family: "Madimi One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .fira-sans-regular {
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .nova-cut-regular {
    font-family: "Nova Cut", system-ui;
    font-weight: 400;
    font-style: normal;
  }

  .over-the-rainbow-regular {
    font-family: "Over the Rainbow", cursive;
    font-weight: 400;
    font-style: normal;
  }
  
  